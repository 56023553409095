
Body {
    background-color: #0e151e   
}

.header-text {
  background: none;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  }


